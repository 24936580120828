import { isPlatformServer } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-not-match-localized-route',
  standalone: true,
  imports: [],
  template: '',
})
export class NotMatchLocalizedRouteComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly localizeRouterService = inject(LocalizeRouterService);
  private platformId = inject(PLATFORM_ID);

  ngOnInit(): void {
    const currentPath = this.router.url;
    const lang = this.localizeRouterService.parser.currentLang;

    if (currentPath.startsWith(`/${lang}/`))
      this.router.navigate([this.localizeRouterService.translateRoute('/404')]);
    else {
      const localizedRoute = this.localizeRouterService.translateRoute(currentPath);

      if (isPlatformServer(this.platformId)) {
        this.router.navigate([localizedRoute]);
      } else {
        location.replace(localizedRoute.toString());
      }
    }
  }
}
