<div class="loader-container d-flex justify-content-center align-content-center flex-column">
    <div class="container shadow d-flex justify-content-center align-content-center flex-column">

        <img src="/assets/images/logos/Full_Color_Black.png" />
        <hr />
        <div class="text-center">
            <div class="spinner-border display-5 text-primary" role="status">
                <span class="visually-hidden">{{ 'loading' | translate }}</span>
            </div>
        </div>
    </div>
</div>