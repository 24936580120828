import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BlockUIModule } from 'ng-block-ui';
import { TranslateModule } from '@ngx-translate/core';
import { WaitingLoaderComponent } from './layout/waiting-loader/waiting-loader.component';
import { filter } from 'rxjs';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, BlockUIModule, TranslateModule, WaitingLoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router);
  private platformLocation = inject(PlatformLocation);
  private localizeRouterService = inject(LocalizeRouterService);
  private platformId = inject(PLATFORM_ID);

  ngOnInit(): void {
    if (this.platformLocation.pathname.endsWith(`/${this.localizeRouterService.parser.currentLang}`)) {
      this.router.navigate([this.localizeRouterService.translateRoute('/404')]);
    }
  }
}
