<div class="container text-center">
  <div class="shadow shadow bg-white p-5 my-5 d-inline-block">

    <div class="mb-5 pb-5 border-bottom d-inline-block">
      <img src="/assets/images/logos/Full_Color_Black.png" />
    </div>
    <div dir="ltr" class="fs-2">
      <p>
        الصفحة المطلوبة غير موجودة
      </p>
      <p>
        Page Not Found
      </p>
    </div>
  </div>

</div>
