import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, forkJoin, map } from "rxjs";

export class CustomMultiTranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient, public resources: {prefix: string, suffix: string}[] = []) {}
  
    public getTranslation(lang: string): Observable<any> {
      return forkJoin(this.resources.map(config => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`);
      })).pipe(map((response: Object[]) => {
        return response.reduce((a, b) => {
          return {...a, ...b};
        });
      }));
    }
  }