import { HttpBackend, HttpClient } from "@angular/common/http";
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ConfigService } from "./services/config.service";
import { CustomMultiTranslateHttpLoader } from "./services/multi-translate-http-loader.service";

// required for AoT
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, ['./assets/i18n/shared.', './assets/i18n/']);
}

export function CustomHttpLoaderFactory(_http: HttpClient): CustomMultiTranslateHttpLoader {
  return new CustomMultiTranslateHttpLoader(_http, [
    { prefix: './assets/i18n/shared.', suffix: '.json' },
    { prefix: './assets/i18n/', suffix: '.json' }
  ]);
}

export function initializeApp(configService: ConfigService) {
  return () => configService.loadConfig();
}