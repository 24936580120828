import { isPlatformServer, isPlatformBrowser } from "@angular/common";
import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent, HttpResponse } from "@angular/common/http";
import { inject, PLATFORM_ID, TransferState, StateKey, makeStateKey } from "@angular/core";
import { Observable, tap, of } from "rxjs";

export const trasferStateInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const platformId = inject(PLATFORM_ID);
    const transferState = inject(TransferState);
  
    if (req.method !== 'GET') return next(req);

    const key = req.url.includes('/api/') ? req.url.slice(req.url.indexOf('/api/')) : req.url;
    const stateKey: StateKey<string> = makeStateKey<string>(key);
  
    // For any http requests made on the server, store the response in State Transfer.
    if (isPlatformServer(platformId)) {
      return next(req).pipe(tap((event: HttpEvent<any>) => { 
          if (event instanceof HttpResponse)
            transferState.set(stateKey, event.body);
      }));
    }
  
    // For any http requests made in the browser, first check State Transfer for a 
    // response corresponding to the request url.
    if (isPlatformBrowser(platformId)) {
      const transferStateResponse = transferState.get<any>(stateKey, null);
      if (transferStateResponse) {
        const response = new HttpResponse({ body: transferStateResponse, status: 200 });
  
        // Remove the response from state transfer, so any future requests to 
        // the same url go to the network (this avoids us creating an 
        // implicit/unintentional caching mechanism).
        transferState.remove(stateKey);
        return of(response);
      }
    }
  
    return next(req);
  }