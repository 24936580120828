<block-ui />

<div id="main" class="d-none">
    <router-outlet></router-outlet>
</div>

<div id="loader">
    <app-waiting-loader />

</div>



<!-- Modal -->
<div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
    <div class="modal-dialog modal-md rounded-0" role="document">
        <div class="modal-content successModal">
            <div class="modal-header border-bottom-0 w-100">
                <h5 class="modal-title"> {{'messages.successOperation' | translate}} </h5>
                <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <i class="bi bi-check-circle text-success display-1"></i>
                <div class="py-4" id="successModalMessage"></div>
            </div>
        </div>
    </div>
</div>