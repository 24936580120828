import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { SiteService } from './site.service';

export const siteKeyInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const siteService = inject(SiteService);

  if (!siteService.extractKey())
    return next(req); 

  // Clone the request and add the "site-key" header
  const modifiedReq = req.clone({
    setHeaders: { 'site-key': siteService.extractKey() }
  });
  // Pass the modified request to the next handler
  return next(modifiedReq);
};
