import { Routes } from '@angular/router';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { NotMatchLocalizedRouteComponent } from './common/not-match-localized-route/not-match-localized-route.component';
import { AppComponent } from '../../../advertising-admin/src/app/app.component';
import { Constants } from '../../../shared/src/lib/constants';

export const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: ':siteKey',
    loadChildren: () => import('./pages/pages.module').then(p => p.PagesModule)
  },
  {
    path: '', pathMatch: 'full', redirectTo: '404'
  },
  {
    path: '**', component: NotMatchLocalizedRouteComponent
  }
];
